import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import Title from '../../components/Title';
import './style.sass';

export default props => {
  return (
    <Layout>
      <Seo
        title="利用規約"
        description="マダムたぬきについての利用規約について"
        href={props.location.href}
      />

      <div className="terms">
        <Title>利用規約</Title>
        <p className="terms__message">
          掲載されている著作物に係る著作権・肖像権は「pocpons」が保有します。
          当サイトが提供する情報、画像、音声等を、権利者の許可なく転載（転用、複製、複写、編集、改変、販売)・無断使用を固く禁じます。
          また、まとめサイト等への引用を厳禁いたします。
        </p>
        <p className="terms__message">
          Unauthorized copying and replication of the contents of this site,
          text and images are strictly prohibited.
        </p>
        <ul className="terms__list">
          <li>
            お客様がpocoponsの提供する『マダムたぬきの夢占い』（以下、本サービスといいます）をご利用されるに当たって、下記のご利用規約をご承諾の上、遵守事項を守りご利用願います。
          </li>
          <li>
            本サービスに関するテキスト・画像の著作権等を含む一切の権利は、pocopons又は著作権を有する第三者に帰属します。
          </li>
          <li>
            お客様が本サービスの内容を無断で転載・複写・蓄積又は転送することを禁止します。
          </li>
          <li>
            お客様は本サービスに関するpocopons及びその他の第三者の権利を脅かしたり、制限したり、若しくは妨害したり又はそのおそれがあるような行動を一切起こしてはならないものとします。
          </li>
          <li>
            お客様が本サービスによりアクセス可能な情報を改ざん、消去する行為を禁止します。
          </li>
          <li>
            お客様が他者になりすまして本サービスを利用する行為を禁止します。
          </li>
          <li>
            お客様が有害なコンピュータプログラム等を送信し、または他者が受信可能な状態におく行為を禁止します。
          </li>
          <li>
            お客様がサービス用設備に無権限でアクセスし、またはその利用もしくは運営に支障を与える行為（与えるおそれのある行為を含む）を禁止します。
          </li>
          <li>
            お客様は、本サービスに関わるいかなる権利又は義務も第三者に移転又は譲渡することはできません。
          </li>
          <li>
            pocoponsは本サービス及び本利用規約を予告なく改訂、追加、変更又は廃止することができるものとします。
          </li>
          <li>
            お客様がこの規約に違反した場合には、本サービスを利用して登録、発信した情報を予告なく削除したり、本サービスの利用をお断りさせていただく場合がありますので、あらかじめご了承下さい。なお、pocoponsが情報を削除したり利用をお断りした場合であっても、お客様の自己責任の原則を否定されませんので、ご注意下さい。
          </li>
          <li>
            お客様に提供する本サービスの情報内容は、正確性・確実性を保証するものではありません。
          </li>
          <li>
            お客様の個人情報は、本サービス、統計データ作成及びお問合わせ対応等の目的のために利用させていただきます。
          </li>
          <li>
            お客様の個人情報は、お客様の同意を得ずに第三者に提供することは原則として行いません。ただし、警察などの公的な機関からの要請に対しては提供することがあります。
          </li>
          <li>
            お客様の個人情報に入力違いや入力漏れ等がある場合、サービスをご利用いただけなかったり、意図していない情報を提供してしまうことがあります。
          </li>
          <li>
            お客様に登録いただいた個人情報の開示等に関するお問い合わせは、こちらよりご連絡いただきます。
          </li>
          <li>
            お客様との間で疑義又は争いが生じた場合には、誠意を持って協議することとしますが、それでもなお解決しない場合には法的措置をとる場合があります。
          </li>
        </ul>
      </div>
    </Layout>
  );
};
